import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import TextField from '@mui/material/TextField'
import {
  CreateInformerSection,
  CreateInformerSectionToMembershipGroupMutation,
  CreateInformerSectionToMembershipGroupMutationVariables,
  CreateInformerSectionVariables,
  DeleteInformerSection,
  DeleteInformerSectionToMembershipGroupMutation,
  DeleteInformerSectionToMembershipGroupMutationVariables,
  DeleteInformerSectionVariables,
  UpdateInformerSectionMutation,
  UpdateInformerSectionMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Modal from 'src/components/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import {
  CREATE_SECTION,
  CREATE_SECTION_TO_GROUP,
  DELETE_SECTION,
  DELETE_SECTION_TO_GROUP,
  UPDATE_SECTION,
} from 'src/lib/queries/Settings/Layout/SettingsLayout'
import { SelectedSection } from 'src/pages/SettingsClientToolsPage/SettingsClientToolsPage'
import { filterArray } from 'src/Util'

import FormInputRow from '../Forms/FormInputRow/FormInputRow'
import IconSelector from '../IconSelector/IconSelector'
import Button from '../Library/Button/Button'
import Switch from '../Library/Switch/Switch'

import { ModalAction } from './SettingsLayoutSection'
import {
  InformerSection,
  SettingsLayoutIcon,
  SettingsLayoutSectionRefetch,
} from './SettingsLayoutSectionCell/SettingsLayoutSectionCell'

const convertMembershipsToSelectableFormat = (
  allMembershipGroups: any[],
  assignedMembershipGroups: any[],
) => {
  // For each assigned group, find the corresponding group name
  const formattedMemberships = assignedMembershipGroups.map((group) => {
    const matchingGroup = allMembershipGroups.find((item) => {
      return item.membershipGroupId === group.membershipGroupId
    })

    const addGroup = {
      membershipGroupId: group.membershipGroupId,
      name: matchingGroup?.name ?? '',
    }

    return addGroup
  })

  return formattedMemberships
}

interface SettingsLayoutSectionModalProps {
  sectionModalOpen: boolean
  setSectionModalOpen: Dispatch<SetStateAction<boolean>>
  modalAction: ModalAction
  globalIcons: SettingsLayoutIcon[]
  selectFromMembershipGroups: any[]
  editingSection: InformerSection
  setEditingSection: Dispatch<SetStateAction<InformerSection>>
  refetch: SettingsLayoutSectionRefetch
  setSelectedSection: Dispatch<SetStateAction<SelectedSection>>
}

const SettingsLayoutSectionModal: FC<SettingsLayoutSectionModalProps> = ({
  sectionModalOpen,
  setSectionModalOpen,
  modalAction,
  globalIcons,
  selectFromMembershipGroups,
  editingSection,
  setEditingSection,
  refetch,
  setSelectedSection,
}) => {
  // Modal state
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  // Image Upload
  const [iconUploadingStatus, setIconUploadingStatus] = useState(false)

  // Section Name Input
  const [sectionNameInputValue, setSectionNameInputValue] = useState<string>('')

  // Section ID of currently editing
  const [sectionIdInputValue, setSectionIdInputValue] = useState<number | null>(
    null,
  )
  const [sectionPinnedInputValue, setSectionPinnedInputValue] = useState(false)
  const [iconStorageObjectValue, setIconStorageObjectValue] = useState(null)

  // Section Membership Groups input
  const [sectionGroupValue, setSectionGroupValue] = useState<any[]>([])
  const [sectionGroupInputValue, setSectionGroupInputValue] =
    useState<string>('')
  const [currentSectionMembershipGroups, setCurrentSectionMembershipGroups] =
    useState<any[]>(null)

  const confirmDeleteSection = useConfirm()

  const [createSectionToGroupMutation] = useMutation<
    CreateInformerSectionToMembershipGroupMutation,
    CreateInformerSectionToMembershipGroupMutationVariables
  >(CREATE_SECTION_TO_GROUP, {
    onCompleted: async () => {
      await refetch()
    },
    onError: (error) => {
      toast.error('Error assigning section to group \n \n' + error.message, {
        duration: 5000,
      })
    },
  })

  const [createSectionMutation] = useMutation<
    CreateInformerSection,
    CreateInformerSectionVariables
  >(CREATE_SECTION, {
    onCompleted: async (completedItem) => {
      // Assign the section to each group selected

      // If memberships, create records and refetch, else refetch now
      if (sectionGroupValue.length) {
        // Create new relations for each saved item
        for (const membershipGroup of sectionGroupValue) {
          // Fire and forget
          createSectionToGroupMutation({
            variables: {
              input: {
                informerSectionId: completedItem.createInformerSection.id,
                membershipGroupId: parseInt(membershipGroup.membershipGroupId),
              },
            },
          })
        }
      } else {
        // Refetch now instead of on section create
        await refetch()
      }

      // Success Message
      toast.success('Section Created', {
        duration: 2000,
      })

      // Reset and Clear modal
      setIsSaving(false)
      setSectionModalOpen(false)

      // Update Selected Section to newly created one
      setSelectedSection({
        id: completedItem.createInformerSection.id,
        name: completedItem.createInformerSection.name,
      })
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
      setIsSaving(false)
    },
  })

  const [deleteSectionToGroupMutation] = useMutation<
    DeleteInformerSectionToMembershipGroupMutation,
    DeleteInformerSectionToMembershipGroupMutationVariables
  >(DELETE_SECTION_TO_GROUP, {
    onError: (error) => {
      toast.error(
        'Error un-assigning your section from the group \n \n' + error.message,
        {
          duration: 5000,
        },
      )
    },
  })

  const [deleteSectionMutation] = useMutation<
    DeleteInformerSection,
    DeleteInformerSectionVariables
  >(DELETE_SECTION, {
    onCompleted: async (completedItem) => {
      await refetch()

      // End loading state and close modal
      setIsDeleting(false)
      setSectionModalOpen(false)

      // Unselect the selected section if we just deleted it
      if (completedItem.deleteInformerSection.id === editingSection?.id) {
        setSelectedSection(null)
      }

      toast.success('Section Deleted', {
        duration: 2000,
      })
    },
    onError: (error) => {
      toast.error('Error Deleting section \n \n' + error.message, {
        duration: 5000,
      })
      setIsDeleting(false)
    },
  })

  const [updateSectionMutation] = useMutation<
    UpdateInformerSectionMutation,
    UpdateInformerSectionMutationVariables
  >(UPDATE_SECTION, {
    onCompleted: async (returnData) => {
      // Updated Section
      const updatedSection = returnData.updateInformerSection

      // The Groups from the input field
      const sectionGroupIdsFromInputField = sectionGroupValue.map(
        (group) => group.membershipGroupId,
      )

      // The groups from existing records
      const sectionGroupIdsFromExisting = currentSectionMembershipGroups.map(
        (group) => group.membershipGroupId,
      )

      // If the user has unselected all records for the section delete, else check if changes were made
      if (
        !sectionGroupIdsFromInputField.length &&
        sectionGroupIdsFromExisting.length
      ) {
        // Delete all the membership assigned records for this section
        for (const deleteGroupRecord of currentSectionMembershipGroups) {
          await deleteSectionToGroupMutation({
            variables: {
              id: deleteGroupRecord.id,
            },
          })
        }
      } else {
        // Conditionally update records

        const createSectionToGroupList = filterArray(
          sectionGroupIdsFromInputField,
          sectionGroupIdsFromExisting,
        )
        const deleteSectionToGroupList = filterArray(
          sectionGroupIdsFromExisting,
          sectionGroupIdsFromInputField,
        )

        // Create new records
        for (const createGroupId of createSectionToGroupList) {
          createSectionToGroupMutation({
            variables: {
              input: {
                informerSectionId: updatedSection?.id,
                membershipGroupId: parseInt(createGroupId),
              },
            },
          })
        }

        // Delete the records that are no longer required
        let deleteId = null
        for (const deleteGroupId of deleteSectionToGroupList) {
          for (const groupItem of currentSectionMembershipGroups) {
            if (groupItem.membershipGroupId === deleteGroupId) {
              deleteId = groupItem.id
              break
            }
          }

          // Item found, delete it
          if (deleteId) {
            deleteSectionToGroupMutation({
              variables: {
                id: deleteId,
              },
            })
          }

          // Reset delete id
          deleteId = null
        }
      }

      // Reset the selected name if the ID matches
      if (updatedSection.id === editingSection?.id) {
        setSelectedSection({
          name: updatedSection.name,
          id: updatedSection.id,
        })
      }

      setIsSaving(false)
      setSectionModalOpen(false)
      await refetch()
      toast.success('Section Updated', {
        duration: 2000,
      })
    },
    onError: (error) => {
      toast.error(' Unable to save section \n \n' + error.message, {
        duration: 5000,
      })
      setIsSaving(false)
    },
  })

  const submitSectionForm = async () => {
    // CREATING
    if (modalAction === 'create') {
      // Set state on loading button
      setIsSaving(true)

      // Create the section
      await createSectionMutation({
        variables: {
          input: {
            name: sectionNameInputValue,
            isPinnedToSidebar: sectionPinnedInputValue,
            iconStorageObjectId: iconStorageObjectValue?.id,
          },
        },
      })
    }

    // EDITING
    if (modalAction === 'edit') {
      // Set state on loading button
      setIsSaving(true)

      // Create the section
      await updateSectionMutation({
        variables: {
          id: sectionIdInputValue,
          input: {
            name: sectionNameInputValue,
            isPinnedToSidebar: sectionPinnedInputValue,
            iconStorageObjectId: iconStorageObjectValue?.id ?? null,
          },
        },
      })
    }
  }

  function resetFormFields() {
    setSectionIdInputValue(null)
    setSectionNameInputValue('')
    setSectionGroupValue([])
    setSectionGroupInputValue('')
    setCurrentSectionMembershipGroups(null)
    setSectionPinnedInputValue(false)
    setIconUploadingStatus(false)
    setIconStorageObjectValue(null)
    setEditingSection(null)
  }

  // Change edit values on newly selected edit option
  useEffect(() => {
    if (editingSection?.id && modalAction === 'edit') {
      setSectionNameInputValue(editingSection?.name)
      setSectionIdInputValue(editingSection?.id)
      setSectionPinnedInputValue(editingSection?.isPinnedToSidebar)
      setIconStorageObjectValue(editingSection?.iconStorageObject)
      setSectionGroupValue(
        convertMembershipsToSelectableFormat(
          selectFromMembershipGroups,
          editingSection?.membershipGroups,
        ),
      )
      setCurrentSectionMembershipGroups(editingSection?.membershipGroups)
    }
  }, [editingSection])

  // Clear on close
  useEffect(() => {
    if (!sectionModalOpen) {
      // On modal close reset form field
      resetFormFields()
    }
  }, [sectionModalOpen])

  return (
    <Modal
      open={sectionModalOpen}
      closeButtonVisible={false}
      onClose={() => setSectionModalOpen(false)}
      className="max-w-2xl !p-0"
      dialogClassName="!bg-white !p-0"
    >
      <div id="sectionModal">
        <div className="flex items-center justify-between p-6 pt-3">
          <h3 className="mb-0 text-lg font-medium leading-6 text-gray-900">
            {modalAction === 'create' && <span>Create Section</span>}
            {modalAction === 'edit' && <span>Editing Section</span>}
          </h3>
          <Button
            fullWidth={false}
            variant="text"
            className="min-w-[0]"
            onClick={() => setSectionModalOpen(false)}
            disabled={isDeleting || isSaving}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
        </div>
      </div>

      <form
        id="saveSection"
        className="border-t border-gray-200"
        onSubmit={(e) => {
          e.preventDefault()
          submitSectionForm()
        }}
      >
        <div className="p-6">
          <FormInputRow label={'sectionName'} title={'Name'}>
            <TextField
              fullWidth
              size="small"
              autoComplete="false"
              id="informerSectionNameInput"
              type="text"
              name="categoryName"
              value={sectionNameInputValue}
              required
              onChange={(e) => setSectionNameInputValue(e.target.value)}
            />
          </FormInputRow>
          {sectionPinnedInputValue && (
            <FormInputRow label={'cardImage'} title={'Logo'}>
              <IconSelector
                selectableIcons={globalIcons}
                onIconSelect={setIconStorageObjectValue}
                setUploadingStatus={setIconUploadingStatus}
                currentIcon={iconStorageObjectValue}
              />
            </FormInputRow>
          )}

          <FormInputRow
            label={'sectionGroups'}
            title={'Select Groups'}
            note="Assigning groups to a section will allow it to be filtered on the home screen. Sections without any assigned groups are universal and will always appear on the home screen when group filters are active."
          >
            <Autocomplete
              className="w-full"
              fullWidth
              size="small"
              value={sectionGroupValue}
              disableCloseOnSelect
              filterSelectedOptions
              multiple
              onChange={(_event, newValue) => {
                setSectionGroupValue(newValue)
              }}
              isOptionEqualToValue={(option, value) =>
                option.membershipGroupId === value.membershipGroupId
              }
              inputValue={sectionGroupInputValue}
              onInputChange={(_event, newInputValue) => {
                setSectionGroupInputValue(newInputValue)
              }}
              id="dialogInputGroupsAll"
              options={selectFromMembershipGroups}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormInputRow>
          <FormInputRow label={'pinToSidebar'} title={'Pinned to Sidebar'}>
            <Switch
              data-action="pin-section"
              checked={sectionPinnedInputValue}
              onChange={(e) => {
                setSectionPinnedInputValue(e.target.checked)
              }}
            />
            {sectionPinnedInputValue && (
              <>
                <p className="pt-3 text-xs text-gray-500">
                  <b>Note:</b> A pinned section will create a menu option in the
                  sidebar. Pinned sections will not appear on the home page.
                </p>
                <p className="mt-3 text-xs text-gray-500">
                  To keep the sidebar menu accessible, it is recommended to use
                  a maximum of 10 cards in a pinned section.
                </p>
              </>
            )}
          </FormInputRow>
        </div>
        <div className="flex items-center justify-between bg-gray-100 px-6 py-4">
          <div>
            {modalAction === 'edit' && (
              <Button
                fullWidth={false}
                className="bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                onClick={() => {
                  confirmDeleteSection({
                    title: 'Delete Section',
                    description:
                      'Are you sure you want to delete this section?',
                  }).then(async (isConfirmed) => {
                    if (!isConfirmed) return
                    setIsDeleting(true)
                    await deleteSectionMutation({
                      variables: {
                        id: sectionIdInputValue,
                      },
                    })
                  })
                }}
                disabled={isDeleting || isSaving || iconUploadingStatus}
                loading={isDeleting}
              >
                Delete Section
              </Button>
            )}
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="text"
              fullWidth={false}
              disabled={isDeleting || isSaving || iconUploadingStatus}
              onClick={() => setSectionModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              buttonDataTestId="informerSectionButtonSave"
              data-action={modalAction}
              type="submit"
              disabled={isDeleting || isSaving || iconUploadingStatus}
              loading={isSaving}
            >
              {modalAction === 'create' && 'Create Section'}
              {modalAction === 'edit' && 'Save Section'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default SettingsLayoutSectionModal
